<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn
            v-if="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="accidentInfo"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고명 -->
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="LBL0002306"
            name="accidentName"
            v-model="accidentInfo.accidentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고번호 -->
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBL0002305"
            name="accidentNo"
            v-model="accidentInfo.accidentNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고종류 -->
          <c-multi-select
            required
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCd"
            label="LBL0002301"
            v-model="accidentInfo.accidentKindCd"
          >
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생일시 -->
          <c-datepicker
            required
            :editable="editable"
            type="datetime"
            :disabled="disabled"
            :minuteStep="10"
            label="LBL0002309"
            name="occurrenceDate"
            v-model="accidentInfo.occurrenceDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공정 -->
          <c-process
            :required="true"
            :editable="editable"
            :disabled="disabled"
            label="LBLPROCESS"
            name="processCd"
            v-model="accidentInfo.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생장소 -->
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="LBL0002320"
            name="occurrenceLocation"
            v-model="accidentInfo.occurrenceLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 보고자 -->
          <c-field
            required
            :editable="editable"
            :disabled="disabled"
            :data="accidentInfo"
            label="LBL0002321"
            type="dept_user"
            name="reportUserId"
            v-model="accidentInfo.reportUserId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 보고일시 -->
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            type="datetime"
            label="LBL0002322"
            :minuteStep="10"
            name="reportDate"
            v-model="accidentInfo.reportDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고등급 -->
          <c-select
            required
            :editable="editable"
            :disabled="disabled"
            type="edit"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentGradeCd"
            label="LBL0002308"
            v-model="accidentInfo.accidentGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="accidentInfo.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생부서 -->
          <c-dept
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="LBL0002302"
            name="occurrenceDeptCd"
            v-model="accidentInfo.occurrenceDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 날씨 -->
          <c-radio
            :editable="editable"
            :disabled="disabled"
            :comboItems="weatherItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="weather"
            label="LBL0002323"
            v-model="accidentInfo.weather"
          ></c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 긴급조치사항 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="8"
            label="LBL0002324"
            name="emergencyMeasures"
            v-model="accidentInfo.emergencyMeasures">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고사항개요 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :isCheck="true"
            :rows="8"
            label="LBL0002325"
            name="overviewAccidentSituation"
            v-model="accidentInfo.overviewAccidentSituation"
            @checkboxClick="checkboxClick"
            >
          </c-textarea>
        </div>
      </template>
    </c-card>
    <!-- <c-card title="발생형태" class="cardClassDetailForm" bgClass="">
      <template slot="card-detail">
      </template>
    </c-card> -->
    <!-- 사고원인 -->
    <c-card title="LBL0002326" class="cardClassDetailForm" bgClass="">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 대분류 -->
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="occFirstItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeLargeCd"
            label="LBL0002327"
            v-model="accidentInfo.occurrenceModeLargeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 중분류 -->
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="occSecItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeSmallCd"
            label="LBL0002328"
            v-model="accidentInfo.occurrenceModeSmallCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 인적피해여부 -->
          <c-radio
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="humanDamageItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="humanDamage"
            label="LBL0002329"
            v-model="accidentInfo.humanDamage"
          ></c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 직접원인 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002330"
            name="directCause"
            v-model="accidentInfo.directCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 간접원인 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002331"
            name="indirectCause"
            v-model="accidentInfo.indirectCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 기인물 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002332"
            name="openning"
            v-model="accidentInfo.openning">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 가해물 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002333"
            name="injurious"
            v-model="accidentInfo.injurious">
          </c-text>
        </div>
      </template>
    </c-card>
    
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-register-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        count: 0,
      }),
    },
  },
  data() {
    return {
      accidentInfo: {
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        humanDamage: 'Y',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        processCd: '',
        toxicFlag: 'N',
        accidentVictimModelList: [],
        accidentMaterialModelList: [],
        
        deleteAccidentVictimModelList: [], // 사고자 삭제 모델:
        emergencyList: [],
        damageClassCd: null,
        woundCd: null,
      },
      occFirstItems: [],
      occSecItems: [],
      weatherItems: [
        { code: 'WC000001', codeName: '맑음' },
        { code: 'WC000002', codeName: '흐림' },
        { code: 'WC000003', codeName: '비' },
        { code: 'WC000004', codeName: '눈' },
      ],
      humanDamageItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '미해당' },
      ],
      editable: true,
      searchUrl: '',

      saveUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      completeUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      saveType: 'POST',
      completeType: 'POST',
      isSave: false,
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      click: 'N',
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.accidentInfo.accidentStatusCd) && this.accidentInfo.accidentStatusCd === 'ISPC000005'
    }
  },
  watch: {
    'accidentInfo.occurrenceModeLargeCd'() {
      this.setSecondItems();
    },
    'accidentInfo'() {
      this.$emit('transInfo', this.accidentInfo);
    },
    'popupParam.count'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
      // list setting
      this.setFirstItems();
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data;
          this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
            if (_item.expectTreatmentDate) {
              _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
            }
          })
          this.updateMode = true;
        },);
      }
    },
    setFirstItems() {
      this.$http.url = selectConfig.sop.iim.occurForm.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        occurLevel: '1',
      }
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.occFirstItems.splice(0, 0, {
            code: _item.iimOccurMasterId,
            codeName: _item.occurName,
          })
        })
      },);
    },
    setSecondItems() {
      if (this.accidentInfo.occurrenceModeLargeCd) {
        this.$http.url = selectConfig.sop.iim.occurForm.list.url;
        this.$http.type = 'GET';
        this.$http.param = {
          iimUpOccurMasterId: this.accidentInfo.occurrenceModeLargeCd,
          useFlag: 'Y',
          occurLevel: '2',
        }
        this.$http.request((_result) => {
          this.occSecItems = this.$_.map(_result.data, item => {
            return {
              code: item.iimOccurMasterId,
              codeName: item.occurName,
            }
          });
          if (this.$_.findIndex(this.occSecItems, { code: this.accidentInfo.occurrenceModeSmallCd }) === -1) {
            this.accidentInfo.occurrenceModeSmallCd = null;
          }
        },);
      } else {
        this.occSecItems = [];
        this.accidentInfo.occurrenceModeSmallCd = null;
      }
    },
    saveAccident() {
      if (this.popupParam.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (!this.popupParam.iimAccidentId) this.accidentInfo.accidentStatusCd = 'ISPC000001'
              this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
                if (_item.expectTreatmentDate) _item.expectTreatmentDate = _item.expectTreatmentDate.join();
              })
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      if (!this.popupParam.iimAccidentId) {
        this.$emit('setReportTab', result.data)
      }
      this.popupParam.iimAccidentId = result.data;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    checkboxClick(checkValue) {
      if (checkValue === 'O') {
        this.accidentInfo.overviewAccidentSituation = this.$comm.getLangLabel('LBL00001549')
          // '- ' +
          // '누가 : \n' + // 누가
          // '- ' +
          // '누구에게 : \n' + // 누구에게
          // '- ' +
          // '언제 : \n' + // 언제
          // '- ' +
          // '어디서 : \n' + // 어디서
          // '- ' +
          // '무엇을 : \n' + // 무엇을
          // '- ' +
          // '어떻게 : \n' + // 어떻게
          // '- ' +
          // '왜 : ';
      } else {
        this.accidentInfo.overviewAccidentSituation = '';
      }
    },
  }
};
</script>
